import React, { useState } from "react"
import "./css/layout.css"
import { Link } from "react-scroll"
import EmailValidator from "email-validator"
import emailjs from "emailjs-com"
import ClearIcon from "@material-ui/icons/Clear"
import { Helmet } from "react-helmet"

const Layout = props => {
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [styleWithErr, setStyleWithErr] = useState({
    emailInput: { border: "1px solid transparent" },
    messageInput: { border: "1px solid transparent" },
  })
  const [emailMessageClass, setEmailMessageClass] = useState("")
  const [emailInputClass, setEmailInputClass] = useState("")
  const [messageClass, setMessageClass] = useState("")
  const [hiddenInput, setHiddenInput] = useState("")
  const [emailSent, setEmailSent] = useState({
    animation: "",
    bg: "email-sent-message-bg-err",
    message: "Your message is sent. I'll answer you as soon as possible",
  })
  const [opacity, setOpacity] = useState({ opacity: "0" })

  const sendOnClick = e => {
    e.preventDefault()
    if (!EmailValidator.validate(email)) {
      setEmailMessageClass("err-message-in")
      setEmailInputClass("email-input-down")
      setStyleWithErr({
        ...styleWithErr,
        emailInput: { border: "1px solid red" },
      })
      if (message.length !== 0) return
    }
    if (message.length === 0) {
      setMessageClass("err-message-in")
      setStyleWithErr({
        ...styleWithErr,
        messageInput: { border: "1px solid red" },
      })
      if (!EmailValidator.validate(email)) {
        setEmailMessageClass("err-message-in")
        setEmailInputClass("email-input-down")
        setStyleWithErr({
          messageInput: { border: "1px solid red" },
          emailInput: { border: "1px solid red" },
        })
      }
      return
    }
    sendingEmail(e)
  }

  const sendingEmail = async e => {
    try {
      setOpacity({ opacity: "1" })
      if (hiddenInput.length !== 0) return
      await emailjs.sendForm(
        "gmail",
        "template_18a1i95",
        e.target,
        "user_1Z0eyWUAMWqLveT6m5gA8"
      )
      setEmail("")
      setMessage("")
      setEmailSent({
        animation: "email-sent-animation",
        bg: "email-sent-message-bg",
        message: "Your message is sent. I'll answer you as soon as possible",
      })
    } catch (err) {
      setEmailSent({
        animation: "email-sent-animation",
        bg: "email-sent-message-bg-err",
        message: "Something went wrong. Try again later.",
      })
    }
  }

  const closeEmailMessage = () => {
    setEmailSent({ ...emailSent, animation: "" })
  }

  const emailOnChange = e => {
    setEmail(e.target.value)
    if (emailMessageClass.length !== 0) {
      setEmailMessageClass("err-message-out")
      setEmailInputClass("email-input-up")
      setStyleWithErr({
        ...styleWithErr,
        emailInput: { border: "1px solid transparent" },
      })
    }
  }

  const messageOnChange = e => {
    setMessage(e.target.value)
    if (emailMessageClass.length !== 0) {
      setMessageClass("err-message-out")
      setStyleWithErr({
        ...styleWithErr,
        messageInput: { border: "1px solid transparent" },
      })
    }
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Vanja Radovanović</title>
        <link rel="" href="" />
      </Helmet>
      <nav className="navbar-main">
        <Link
          className="nav-link"
          activeClass="active"
          to="about-container"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
        >
          About me
        </Link>
        <Link
          className="nav-link"
          activeClass="active"
          to="projects-title"
          spy={true}
          smooth={true}
          offset={-40}
          duration={500}
        >
          Projects
        </Link>
        <Link
          className="nav-link"
          activeClass="active"
          to="contact-container"
          spy={true}
          smooth={true}
          offset={-40}
          duration={500}
        >
          Contact
        </Link>
      </nav>
      {props.children}
      <h1 className="projects-title contact-title">Contact</h1>
      <form onSubmit={sendOnClick}>
        <div className="contact-container">
          <div style={styleWithErr.emailInput} className="email-input input">
            <div className={`email-not-valid-message ${emailMessageClass}`}>
              E-MAIL IS NOT VALID
            </div>
            <input
              className={emailInputClass}
              value={email}
              placeholder="Your e-mail"
              onChange={emailOnChange}
              name="email"
            />
          </div>
          <div
            style={styleWithErr.messageInput}
            className="message-input input"
          >
            <div className={`email-not-valid-message ${messageClass}-textarea`}>
              MESSAGE CAN'T BE EMPTY
            </div>
            <textarea
              placeholder="Your message"
              value={message}
              onChange={messageOnChange}
              name="message"
            />
          </div>
          <input
            type="hidden"
            className="validation-input"
            value={hiddenInput}
            onChange={e => setHiddenInput(e.target.value)}
          />
          <button type="submit">SEND</button>
        </div>
      </form>
      <div
        style={opacity}
        className={`email-sent-message ${emailSent.bg} ${emailSent.animation}`}
      >
        <p>{emailSent.message}</p>
        <button onClick={closeEmailMessage}>
          <ClearIcon />
        </button>
      </div>
    </>
  )
}

export default Layout
