import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../components/css/indexPage.css"
import Typed from "react-typed"
import GitHubIcon from "@material-ui/icons/GitHub"
import "../components/css/projects.css"
import LinkedInIcon from "@material-ui/icons/LinkedIn"

const index = ({ data }) => {
  console.log(data, "data")

  return (
    <Layout>
      <div className="header-img-container">
        <img
          className="header-img"
          alt="err"
          src={data.wordpress.pages.nodes[0].landingPage.header.image.sourceUrl}
        />
      </div>
      <h2 className="header-title">
        Vanja
        <br />
        Radovanović
      </h2>
      <h4 className="header-alt-text">
        <Typed
          strings={data.wordpress.pages.nodes[0].landingPage.header.sentences.map(
            val => val.sentence
          )}
          typeSpeed={50}
          smartBackspace={true}
          startDelay={600}
          loop={true}
          backSpeed={40}
          backDelay={3000}
        />
      </h4>

      <div className="about-outher-container">
        <div className="about-container">
          <div className="about-img-container">
            <img
              src={
                data.wordpress.pages.nodes[0].landingPage.aboutUs.image
                  .sourceUrl
              }
              alt="err"
            />
          </div>
          <h1>About me</h1>
          <p className="about-description">
            <p>
              I am a first-year student at the Faculty of Electrical Engineering
              and Computing in Zagreb. Due to my interest in programming and
              robotics, I have been actively involved in website development for
              the past four years. During this time, I have independently and
              collaboratively created several websites for businesses in
              Croatia.
            </p>
            <p>
              I am characterized by a strong desire to acquire new knowledge in
              the field of modern IT programming technologies. Persistence,
              resourcefulness and meticulousness are my main attributes.
              Currently, I am seeking a professional opportunity that will allow
              me to enhance my existing skills and acquire new ones. I consider
              every new experience as a significant value for my personal and
              carrier development.
            </p>
            <p>
              If you are in search of a young and ambitious web developer who is
              ready for challenges, I am excited for the possibility of becoming
              part of your team. The projects I have completed so far serve as
              references demonstrating my responsible and highly motivated
              approach to new projects, with a focus on quality.
            </p>
            <div className="project-buttons">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/vanja-radovanovi%C4%87-81b960251/"
              >
                <button className="linked-in">
                  <LinkedInIcon fontSize="20" />
                </button>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/VanjaRadovanovic"
              >
                <button className={`github-icon`}>
                  <GitHubIcon />
                </button>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://vr-softdev.kreda.hr/wp/wp-content/uploads/2023/05/CV-Vanja-Radovanovic-ENG.pdf"
              >
                <button>CV</button>
              </a>
            </div>
          </p>
        </div>
      </div>

      <div className="skills">
        <h3 className="title">Skills</h3>
        <div className="container">
          <h3 className="section-title">Advanced</h3>
          <div className="advanced tech-used">
            <div>
              <a target="_blank" rel="noopener noreferrer">
                HTML
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://sass-lang.com/"
              >
                SCSS
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer">
                JavaScript
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://react.dev/"
              >
                React.js
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://nextjs.org/"
              >
                Next.js
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hr.wikipedia.org/wiki/Optimizacija_web_stranice"
              >
                SEO
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hr.wikipedia.org/wiki/Optimizacija_web_stranice"
              >
                Bootstrap
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://hr.wikipedia.org/wiki/Optimizacija_web_stranice"
              >
                Tailwind css
              </a>
            </div>
          </div>
          <h3 className="section-title">Basics</h3>
          <div className="beginner tech-used">
            <div>
              <a target="_blank" rel="noopener noreferrer">
                PHP
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://laravel.com/"
              >
                Laravel
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer">
                Java
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer">
                C
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.w3schools.com/sql/"
              >
                SQL
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://redux.js.org/"
              >
                Redux
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer">
                Linux
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://httpd.apache.org/"
              >
                Apache
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://graphql.org/"
              >
                GraphQL
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://socket.io/"
              >
                Socket.io
              </a>
            </div>
          </div>
          <h3 className="section-title">CMS</h3>
          <div className="beginner tech-used">
            <div>
              <a
                href="https://wordpress.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Headless Wordpress
              </a>
            </div>
            <div>
              <a
                href="https://statamic.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Statamic
              </a>
            </div>
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://strapi.io/"
              >
                Strapi
              </a>
            </div>
            <div>
              <a
                href="https://directus.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Directus
              </a>
            </div>
          </div>
        </div>
      </div>

      <h1 className="projects-title">PROJECTS</h1>
      <section className="projects">
        {data.wordpress.pages.nodes[0].landingPage.projects.map(val => (
          <div className="project-container" key={val.id}>
            <div className="project-img-container">
              <img
                className="project-img"
                src={val.project.projects.image.sourceUrl}
                alt="err"
              />
            </div>
            <h3 className="project-title">{val.project.projects.title}</h3>
            <div className="project-text-container">
              <div className="project-title-container">
                <p style={{ marginBottom: "20px" }}>
                  {val.project.projects.typeOfSoft}
                </p>

                {val.project.projects.backendtech !== null ? (
                  <div className="tech-used">
                    <p>Backed tech:</p>
                    {val.project.projects.backendtech.map(val => (
                      <div>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          key={val.id}
                          href={val.techUrl}
                        >
                          {val.tech}
                        </a>
                      </div>
                    ))}
                  </div>
                ) : null}

                {val.project.projects.frontendTech !== null ? (
                  <div className="tech-used">
                    <p>Frontend tech</p>
                    {val.project.projects.frontendTech.map(val => (
                      <div>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          key={val.id}
                          href={val.techUrl}
                        >
                          {val.tech}
                        </a>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
              <p className="project-description">
                {val.project.projects.description}
              </p>
              <div className="project-buttons">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={val.project.projects.links.deployedLink}
                >
                  <button>LIVE PREWIEV</button>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={val.project.projects.links.githubLink}
                >
                  {val.project.projects.links.githubLink !== null ? (
                    <button className={`github-icon`}>
                      <GitHubIcon />
                    </button>
                  ) : null}
                </a>
              </div>
            </div>
          </div>
        ))}
      </section>
    </Layout>
  )
}

export default index

export const dataQuery = graphql`
  query MyQuery {
    wordpress {
      pages {
        nodes {
          landingPage {
            aboutUs {
              description
              image {
                sourceUrl
              }
            }
            header {
              sentences {
                sentence
              }
              image {
                sourceUrl
              }
            }
            projects {
              project {
                ... on WORDPRESS_Project {
                  id
                  slug
                  projects {
                    title
                    description
                    backendtech {
                      tech
                      techUrl
                    }
                    frontendTech {
                      tech
                      techUrl
                    }
                    image {
                      sourceUrl
                    }
                    links {
                      githubLink
                      deployedLink
                    }
                    typeOfSoft
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
